@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  border: solid;
  border-color:#B78316;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.5s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #B78316;
}

/* Style the accordion content title */
.accordion__title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 20px;
  padding-top: 5px;
  height: 30px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  display: inline-grid;
  padding: 10px 10px 0 10px;
  grid-template-columns: auto auto auto;
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion image */
.accordion__image__container {
  vertical-align: middle;
}

.accordion__image {
  text-align: left;
}

/* Style the accordion content text */
.accordion__text__container {
  text-align: center;
  padding-top: 10px;
}

.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  padding: 0 10px;
}

/* Style the accordion button */
.accordion__btn__container{
  text-align: right;
  padding-top: 8px;
}

.accordion__btn {
  color: #ffffff;
  background-color: #B78316;
  border: 1px solid #B78316;
  border-radius: 5px;
  cursor: pointer;
}

.accordion__btn:hover {
  background-color: #a07f39;
}