.app {
  background-image: url('images/sensorium-background-blurred.png');
  background-repeat: no-repeat;
  background-size: 100vw 100vh;
  background-color: #000000;
  align-items: center;
  overflow: hidden;
}

.content-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title-container {
  color: #B78316
}

.logon-btn-container {
  text-align: center;
}

.logon-btn {
  font-size: 14px;
	font-weight: 400;
	margin: 20px 0px 10px 0px;
	height: 40px;
	width: 100px;
	color: #B78316;
	background-color: #000000;
	border-radius: 5px;
	border: 2px solid #916e06;
}

.logon-btn:hover {
  color: #fff;
	background-color: #916e06;
	border: 2px solid #916e06;
  cursor: pointer;
}