.toolbar-container {
  display: inline-grid;
  grid-template-columns: 100px 300px auto 40px;
  grid-template-rows: 70px 70px 70px 70px;
  align-items: center;
  overflow: hidden;
  position: fixed;
  height: 70px;
  top: 20px;
  left: 20px;
  right: 20px;
  color: white;
  background-color: black;
  border-radius: 5px;
  font-size: calc(10px + 2vmin);
  opacity: .65;
  transition: opacity .3s;
}

.toolbar-container:hover {
  opacity: 1;
}

.burger-title-container {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  text-align: left;
  padding-left: 25px;
}

.text-title-container {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  text-align: left;
  font-family: Gill Sans MT,Calibri;
  font-weight: bold;
  font-size: 32px;
}

.user-container {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  color: white;
  text-align: right;
  font-family: Gill Sans MT,Calibri;
  font-weight: bold;
  font-size: 20px;
  padding-right:25px;
  padding-bottom: 5px;
}

.menu-container {
  grid-column: 4 / 4;
  grid-row: 1 / 2;
  color: white;
  text-align: right;
  font-family: Gill Sans MT,Calibri;
  font-weight: bold;
  font-size: 36px;
  padding-right:25px;
  padding-bottom: 5px;
}

.user-icon {
  width: 25px;
  height: 25px;
}

.user-icon:hover {
  cursor: pointer;
}

.burger-icon {
  width: 30px;
  height: 25px;
}

.burger-icon:hover {
  cursor: pointer;
}

.login-status-container {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  color: white;
  text-align: right;
  font-family: Gill Sans MT,Calibri;
  font-weight: bold;
  font-size: 12px;
}