.user-menu-dropdown-container {
  overflow: hidden;
  position: fixed;
  width:  200px;
  top: 95px;
  right: 20px;
  color: white;
  background-color: #000000;
  border-radius: 5px;
  font-size: 22px;
  opacity: .65;
  transition: opacity .3s;
}

.user-menu-dropdown-container:hover {
  opacity: 1;
}

.user-menu-item {
  color: #ffffff;
  list-style-type: none;
  text-align: right;
  padding-right: 30px;
  padding-top: 10px;
}

.user-menu-item:hover {
  color: #B78316;
  cursor: pointer;
}