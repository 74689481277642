.main-container {
  position: fixed;
  overflow: auto;
  text-align: center;
  background-image: url('../images/sensorium-background.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: #000000;
  color: white;
  height: 100vh;
  width:  100vw;
}

.accordion-container {
  width: 45%;
  margin-top: 200px;
  margin-left: 45px;
}