.burger-menu-dropdown-container {
  overflow: hidden;
  position: fixed;
  width:  240px;
  top: 95px;
  left: 20px;
  color: white;
  background-color: #000000;
  border-radius: 5px;
  font-size: 22px;
  opacity: .65;
  transition: opacity .3s;
}

.burger-menu-dropdown-container:hover {
  opacity: 1;
}

.burger-menu-item-container {
  padding-left: 28px;
}

.burger-menu-item {
  color: #ffffff;
  list-style-type: none;
  text-align: left;
  padding-top: 10px;
}

.burger-menu-item:hover {
  color: #B78316;
  cursor: pointer;
}

